import { NuxtLink } from "#components";

export default {
	props: {
		editable: {
			type: Boolean,
		},
		nonEditable: {
			type: Boolean,
		},
	},
	computed: {
		isNuxt() {
			return !!this.$nuxt;
		},
		target() {
			return this.openInNewWindow ? "_blank" : "_self";
		},
		type() {
			if (this.$config.public.isPreview) return resolveComponent("LazyGizmoLink");
			if (this.isNuxt) return NuxtLink;
			else if (this.customType) return this.customType;
			return "span";
		},
		url() {
			let link = this.to || this.href;
			link = link?.replace("index.page", "");
			return link;
		},
	},
};
